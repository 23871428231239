import type { StatusNotificationType } from '@temperworks/types'
import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

interface StatusNotification {
  id: string,
  open: boolean
  type: StatusNotificationType
  text: string
  buttonText?: string
  withCloseButton?: boolean,
  action?: () => void
}

const notifications = ref<StatusNotification[]>([]);

export const useStatusNotificationStore = defineStore('status-notification-store', () => {

  const lastInfoNotificationId = computed(() => {
    return notifications.value.length > 0 && (notifications.value.filter(notification => notification.type === 'info').pop()?.id ?? '') || ''
  })

  const lastSuccessNotificationId = computed(() => {
    return notifications.value.length > 0 && (notifications.value.filter(notification => notification.type === 'success').pop()?.id ?? '') || ''
  })

  const lastErrorNotificationId = computed(() => {
    return notifications.value.length > 0 && (notifications.value.filter(notification => notification.type === 'danger').pop()?.id ?? '') || ''
  })

  const firstSuccessNotificationWithAction = computed(() => {
    return  notifications.value.length > 0 && notifications.value.find(notification => notification.type === 'success' && notification.action)
  })

  function addNotification(config: Omit<StatusNotification, 'id'>) {
    const timestamp = Date.now();
    const notification = { id: `${config.type}-${timestamp}`, ...config };
    notifications.value.push(notification);
  }

  function resetNotifications(type?: StatusNotificationType) {
    if(!type) {
      notifications.value = []
    } else {
      notifications.value = notifications.value.filter((notification) => notification.type !== type)
    }
  }

  function removeNotification(id:string) {
    notifications.value = notifications.value.filter((notification) => notification.id !== id)
  }

  function closeNotification(id:string) {
    let notificationToClose =  notifications.value.find((notification) => notification.id === id)

    if(notificationToClose?.open && notificationToClose.type === 'success') {
      removeNotification(notificationToClose.id)
    } else if(notificationToClose) {
      notificationToClose.open = false
    } else {
      console.error('statusNotificationHandler closeNotification error: notification is undefined')
    }
  }

  function showNotification(id:string) {

    if (id === '') {
      console.error('statusNotificationHandler showNotification error: id is empty string')
    }

    let notificationToShow = notifications.value.find((notification) => notification.id === id)

    if (notificationToShow) {
      notificationToShow.open = true
    } else {
      console.error('statusNotificationHandler showNotification error: notification is undefined')
    }

  }

  return {
    notifications,
    lastInfoNotificationId,
    lastSuccessNotificationId,
    lastErrorNotificationId,
    firstSuccessNotificationWithAction,
    addNotification,
    removeNotification,
    closeNotification,
    showNotification,
    resetNotifications
  }
})
